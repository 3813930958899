import axios from "axios";
import Cookies from "js-cookie";
import { GetCategories } from "@/services/config";
export default {
	name: "ReviewLeft",
	data() {
		return {
			subTitleArr: [],
			isActive: ""
		};
	},
	created() {
		this.tokenVal = Cookies.get("token");
		this.axios
			.post(
				GetCategories,
				{},
				{
					headers: { token: this.tokenVal }
				}
			)
			.then(res => {
				if (res.data.code == 200) {
					let titleArr = JSON.parse(res.data.data);
					this.subTitleArr = titleArr.SubCategories.Category;
				}
			});
	},
	methods: {
		select(e) {
		},
		subTitle(index) {
			this.$emit("subTitle", index);
			this.isActive = index;
		},
		backList() {
			this.$router.push({ name: "videoList" })
		}
	},
	components: {
		axios
	}
};
