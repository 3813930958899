import Header from "@/components/home/HeaderJx";
import ReviewLeft from "@/components/courseware/CoursewareLeft";
export default {
	name: "index",
	methods: {
		lisLogFn() {
		}
	},
	components: {
		Header,
		ReviewLeft
	}
};
