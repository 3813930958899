
import Nav from './Nav.vue'
import PubSub from '@/lib/Pub'
export default {
	name: 'Header',
	props: ['activeBar',],
	methods: {
		login() {
			PubSub.publish('showLoginEvent')
		},
		loginOut() {
			PubSub.publish('loginOutEvent')
			if (this.$root.userInfo.type == 4) {
				this.$router.push({ path: '/' });
			}
		},
		register() {
			PubSub.publish('registerUser')
		},
		backIndex() {
			this.$router.push({ path: '/' })
		}
	},
	components: {
		Nav,
	},
}
